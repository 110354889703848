$colors: "YELLOW", "DARK_BLUE", "LIGHT_BLUE", "RED", "ORANGE", "DARK_GREEN", "LIGHT_GREEN", "VIOLET", "BROWN", "GREY";
$primary-color: (
        "YELLOW": #ffb300,
        "DARK_BLUE": #0277bd,
        "LIGHT_BLUE": #4fc3f7,
        "RED": #d84315,
        "ORANGE": #ff8f00,
        "DARK_GREEN": #558b2f,
        "LIGHT_GREEN": #aed581,
        "VIOLET": #9575cd,
        "BROWN": #795548,
        "GREY": #607d8b
);
$complementary-color: (
        "YELLOW": #BD4802,
        "DARK_BLUE": #77BD02,
        "LIGHT_BLUE": #F74FC3,
        "RED": #4315D8,
        "ORANGE": #8F00FF,
        "DARK_GREEN": #2F558B,
        "LIGHT_GREEN": #81AED5,
        "VIOLET": #75CD95,
        "BROWN": #487955,
        "GREY": #8B607D
);

$breakpoints: (
        'xs': 0px,
        'sm': 600px,
        'md': 900px,
        'lg': 1200px,
        'xl': 1536px
) !default;

@mixin respond-to($breakpoint) {
    // If the key exists in the map
    @if map-has-key($breakpoints, $breakpoint) {
        // Prints a media query based on the value
        @media (min-width: map-get($breakpoints, $breakpoint)) {
            @content;
        }
    }
}

@mixin portrait() {
    @media screen and (orientation:portrait) {
        @content
    }
}

@mixin landscape() {
    @media screen and (orientation:landscape) {
        @content
    }
}

@each $color in $colors {
    .background-gradient-#{$color} {
        background: map-get($primary-color, $color);
        background: linear-gradient(149deg, map-get($primary-color, $color) 0%, map-get($complementary-color, $color) 100%);
        background: -o-linear-gradient(149deg, map-get($primary-color, $color) 0%, map-get($complementary-color, $color) 100%);
        background: -ms-linear-gradient(149deg, map-get($primary-color, $color) 0%, map-get($complementary-color, $color) 100%);
        background: -moz-linear-gradient(149deg, map-get($primary-color, $color) 0%, map-get($complementary-color, $color) 100%);
        background: -webkit-linear-gradient(149deg, map-get($primary-color, $color) 0%, map-get($complementary-color, $color) 100%);
    }

    .svg-gradient-#{$color} {
        fill: map-get($primary-color, $color);
        fill: linear-gradient(149deg, map-get($primary-color, $color) 0%, map-get($complementary-color, $color) 100%);
        fill: -o-linear-gradient(149deg, map-get($primary-color, $color) 0%, map-get($complementary-color, $color) 100%);
        fill: -ms-linear-gradient(149deg, map-get($primary-color, $color) 0%, map-get($complementary-color, $color) 100%);
        fill: -moz-linear-gradient(149deg, map-get($primary-color, $color) 0%, map-get($complementary-color, $color) 100%);
        fill: -webkit-linear-gradient(149deg, map-get($primary-color, $color) 0%, map-get($complementary-color, $color) 100%);
    }
}

:root {
    --appbar-height: 64px;
    --sidebar-width: 350px;

    --basic-padding: 16px;
    --basic-card-padding: 8px;

    @include respond-to("md") {
        --basic-padding: 32px;
        --basic-card-padding: 16px;
    }

    --safe-area-inset-top: 0;
    --safe-area-inset-bottom: 0;
    --safe-area-inset-left: 0;
    --safe-area-inset-right: 0;

    @supports (padding: env(safe-area-inset-top)) {
        --safe-area-inset-top: env(safe-area-inset-top);
    }
    @supports (padding: env(safe-area-inset-bottom)) {
        --safe-area-inset-bottom: env(safe-area-inset-bottom);
    }
    @supports (padding: env(safe-area-inset-right)) {
        --safe-area-inset-right: env(safe-area-inset-right);
    }
    @supports (padding: env(safe-area-inset-left)) {
        --safe-area-inset-left: env(safe-area-inset-left);
    }

    --calculated-safe-paddign-top: calc(var(--basic-padding) + var(--safe-area-inset-top));
    --calculated-safe-paddign-bottom: calc(var(--basic-padding) + var(--safe-area-inset-bottom));
    --calculated-safe-paddign-left: calc(var(--basic-padding) + var(--safe-area-inset-left));
    --calculated-safe-paddign-right: calc(var(--basic-padding) + var(--safe-area-inset-right));
}

.appbar {
    display: block;
    position: sticky;
    height: calc(var(--appbar-height) + var(--safe-area-inset-top));
    top: 0;
    padding: var(--safe-area-inset-top) var(--safe-area-inset-right) 0 var(--safe-area-inset-left);

    @include respond-to("md") {
        display: none;
    }
}

.sidebar-mobile-wrapper {
    .sidebar {
        display: flex;
        flex-direction: column;
        height: 100dvh;
        width: 100dvw;
        padding: var(--calculated-safe-paddign-top) var(--calculated-safe-paddign-right) var(--calculated-safe-paddign-bottom) var(--calculated-safe-paddign-left);
        scrollbar-gutter: stable both-edges;
        overflow: auto;
    }
}

.layout {
    padding: calc(var(--calculated-safe-paddign-top) + var(--appbar-height)) var(--calculated-safe-paddign-right) var(--calculated-safe-paddign-bottom) var(--calculated-safe-paddign-left);
    display: flex;
    flex-direction: row;
    min-height: 100dvh;


    @include respond-to("md") {
        padding: var(--calculated-safe-paddign-top) var(--calculated-safe-paddign-right) var(--calculated-safe-paddign-bottom) var(--calculated-safe-paddign-left);

    }

    .sidebar-wrapper {
        height: calc(100dvh - var(--calculated-safe-paddign-top) - var(--calculated-safe-paddign-bottom));
        position: sticky;
        top: var(--calculated-safe-paddign-top);
        margin-right: var(--basic-padding);

        .sidebar {
            display: flex;
            flex-direction: column;
            height: calc(100dvh - var(--calculated-safe-paddign-top) - var(--calculated-safe-paddign-bottom));
            width: var(--sidebar-width);
            padding: var(--basic-card-padding);
            scrollbar-gutter: stable both-edges;
            overflow: auto;
        }
    }

    .paper-content {
        padding: var(--basic-padding);
    }
}

.text-clip {
    -o-background-clip: text;
    -ms-background-clip: text;
    -moz-background-clip: text;
    -webkit-background-clip: text;
    -o-text-fill-color: transparent;
    -ms-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    -webkit-text-fill-color: transparent;
}

.flex-split {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    @include respond-to('lg') {
        flex-direction: row;
    }
}

.flex-split-item {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-grow: 5;
}

.flex-split-separator {
    display: block;
    padding: 30px 0;

    @include respond-to("lg") {
        display: flex;
        padding: 0 30px;
    }

    hr {
        @include respond-to("lg") {
            border-width: 0;
            border-style: solid;
            border-color: rgba(0, 0, 0, 0.12);
            border-bottom-width: 0;
            height: auto;
            border-right-width: thin;
        }
    }
}

.pay-by-square {
    background-image: url("https://eshop.motokarymartin.sk/img/paybysquare.png");
    height: 400px;
    width: 400px;
    background-size: contain, cover;
    display: flex;
    align-items: center;
    justify-content: center;
}